/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, {useEffect} from "react";
import { Config, Auth, Login } from "@cleeng/mediastore-sdk";
import "@adyen/adyen-web/dist/adyen.css";
import Offers from "./offers";
import { useRouter } from "next/router";
import '../i18n';
import { useTranslateLang } from "~/translateLangContext";

export default function Home() {

  const handleLoginSuccess = () => {
    window.gtag?.('config', process.env.NEXT_PUBLIC_GA_CONFIG, {
      'user_id': localStorage.CLEENG_CUSTOMER_ID
    });
    window.gtag?.('event', 'login', {
      method: 'Cleeng'
    });
    location.reload()
  }

  const { key2 } = useTranslateLang();
  const router = useRouter()

  // useEffect(() => {
  //   if(router.query.lng !== key2){
  //     (async() => {
  //       try {
  //     await router.replace({ pathname: router.pathname, query: { ...router.query, lng: key2}}, undefined, {shallow: true});
  //       } catch (err) {
  //         console.error(err);
  //       }
  //   })
  // }
  // }, [key2, router]);

 useEffect(()=>{
    if(typeof window !== "undefined"){
      Config.setLanguage(key2);
   }
  }, [key2]);



  return (
    <div>
      {Auth.isLogged() ?
        <Offers /> :
        <div className="index-login-div">
          <Login
            onSuccess={() => handleLoginSuccess()}
            onRegisterClick={() => router.push('/register')}
            onPasswordResetClick={() => router.push('/forgotPassword')}
          />
        </div>
      }
    </div>
  );
}
