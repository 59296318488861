import React, { createContext, useContext, type ReactNode } from "react";

interface TranslateLangState {
  key2: string;
  key4: string;
}

const TranslateLangContext = createContext<TranslateLangState>({
  key2: "",
  key4: "",
});

// determine the initial language state based on browser
const determineInitialLangState = (): TranslateLangState => {
  // Ensure we are in the browser environment
  if (typeof window !== "undefined") {
    return navigator.languages[0]?.includes("fr") ? { key2: "fr", key4: "fr_CA" } : { key2: "en", key4: "en_US" };
  }

  return { key2: "en", key4: "en_US" };
};

export const TranslateLangProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const translateLang = determineInitialLangState();

  return (
    <TranslateLangContext.Provider value={translateLang}>
      {children}
    </TranslateLangContext.Provider>
  );
};

export const useTranslateLang = (): TranslateLangState => {
  const context = useContext(TranslateLangContext);
  if (!context) {
    throw new Error(
      "useTranslateLang must be used within a TranslateLangProvider"
    );
  }
  return context;
};
