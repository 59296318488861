import styles from "../index.module.css";
import Link from "next/link";
import { useEffect, useState } from "react";
import "@adyen/adyen-web/dist/adyen.css";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { Trans } from "react-i18next";
import { useTranslateLang } from "~/translateLangContext";


export default function Offers() {

  const [offerData, setOfferData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { key4 } = useTranslateLang();

  const getOffers = async () => {
    await axios.get(`https://${process.env.NEXT_PUBLIC_CAPI_HOST}/v1/apps/${process.env.NEXT_PUBLIC_APP_LOOKUP_KEY}/offers?locale=${key4}`).then((res) => {
      const filteredRes: [] = res.data.data.filter((offer: any) => offer.customData?.hidden !== 'true')
      setOfferData(filteredRes ?? []);
      setIsLoading(false);
    }).catch((err) => {
      console.log(err);
    });
  };

  useEffect(() => {
    if (typeof window !== undefined) {
      setIsLoading(true);
      getOffers().finally(() => {
        setIsLoading(false);
      });
    }
  }, []);

  return (

    <div className={styles.main}>
      <Link href={`/userAccount`} style={{ width: "50%", margin: "auto", textAlign: "center" }} className={styles.buttonDiv}>
        <Trans i18nKey='buttons.viewSubs'>
        View Active Subscriptions
        </Trans>
        </Link>
      <div className={styles.offerCardContainer}>
        {isLoading ? <p><ClipLoader color={"#28B0DE"} size={100} /></p> :
          offerData.map((offer: any) =>
            <div className={styles.offerCard} key={offer.offerId}>
              <h1 style={{ flexWrap: 'wrap', wordBreak: 'break-word' }}>{offer.title}</h1>
              <h3>{offer.offerType}</h3>
              {offer.imageUrl ? <img src={offer.imageUrl} alt={offer.title} style={{ maxWidth: '100px', maxHeight: '100px' }} /> : ''}
              <p>{!offer.description ? offer.title + " " + offer.offerType : offer.description}</p>
              <p>{offer.price.formattedCurrency} {offer.price.currency}</p>
              <Link href={`/offer?id=${offer.offerId}`} className={styles.buttonDiv}>
                <Trans i18nKey='buttons.offer'>
                SELECT OFFER
                </Trans>
                </Link>
            </div>
          )
        }
      </div>
    </div>
  );
}