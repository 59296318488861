import i18next from 'i18next'
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector'
import translationLanguages from './translations.json'

  void i18next
      .use(initReactI18next)
      .use(LanguageDetector)
      .init({
          debug: true,
          fallbackLng: ['fr'],
          interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
          },
          resources: translationLanguages
  });


export default i18next;