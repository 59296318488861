import { useState, useEffect, useCallback } from "react";
import { Auth } from "@cleeng/mediastore-sdk";
import styles from "../pages/index.module.css";
import Link from "next/link";
import MenuIcon from '@mui/icons-material/Menu';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import CloseIcon from '@mui/icons-material/Close';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { Trans } from "react-i18next";
import { useRouter } from 'next/router';

import axios from "axios";

export default function Nav({ }) {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [urlWithToken, setUrlWithToken] = useState('');
  const router = useRouter();


  useEffect(() => {
    if (window) {
      setIsLoggedIn(Auth.isLogged() ? true : false);
      getRedirectUrlFromSearchParams();

      if (!Auth.isLogged()) {
        const url = localStorage.getItem('redirectUrl');
        setUrlWithToken(url ?? process.env.NEXT_PUBLIC_REDIRECT_URL!);
      }

      if (Auth.isLogged()) {

        const initOffersAndToken = async () => {
          await refreshToken();
          await getFanreachToken();

          const url = `${localStorage.getItem('redirectUrl')!}?token=${localStorage.getItem('FANREACH_TOKEN')}`;
          setUrlWithToken(url ?? process.env.NEXT_PUBLIC_REDIRECT_URL!);
        };
        initOffersAndToken().catch((err) => {
          console.log(err);
        });
      }
    }
  }, []);

  useEffect(() => {
    if (window) {
      const authToken = localStorage.getItem('CLEENG_AUTH_TOKEN')!
      if (router.asPath.includes('/offers') && authToken) {
        // force nav to show logged in items after registration
        setIsLoggedIn(Auth.isLogged() ? true : false);
      }
    }
  }, [router.asPath]);

  const getRedirectUrlFromSearchParams = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const paramRedirectUrl = searchParams.get('redirectURL');
    const currentStoredRedirectUrl = localStorage.getItem('redirectUrl') ?? paramRedirectUrl;
    if (currentStoredRedirectUrl) {
      localStorage.setItem('redirectUrl', currentStoredRedirectUrl);
    } else {
      localStorage.setItem('redirectUrl', process.env.NEXT_PUBLIC_REDIRECT_URL!);
    }
  }

  const refreshToken = useCallback(async () => {
    const refreshToken = localStorage.getItem('CLEENG_REFRESH_TOKEN');
    const authToken = localStorage.getItem('CLEENG_AUTH_TOKEN')!

    // check expiration
    const tokenParts = authToken.split('.');
    const encodedPayload = tokenParts[1];
    const rawPayload = atob(encodedPayload ?? '');

    if (!rawPayload) return console.error('CLEENG_AUTH_TOKEN is not available');

    const payload = JSON.parse(rawPayload);
    const expiresAt = payload.exp * 1000;
    const timeNow = new Date().getTime();

    if (timeNow > expiresAt) {
      try {
        const { data } = await axios.post('https://mediastoreapi.cleeng.com/auths/refresh_token', {
          refreshToken: refreshToken
        }, {
          headers: {
            'accept': 'application/json',
            'content-type': 'application/json'
          }
        })
        localStorage.setItem('CLEENG_AUTH_TOKEN', data.responseData.jwt as string);
        localStorage.setItem('CLEENG_REFRESH_TOKEN', data.responseData.refreshToken as string);
      } catch (error) {
        console.error('There was an error!', error);
      }
    }
  }, []);

  const getFanreachToken = useCallback(async () => {
    const prodURL = `https://${process.env.NEXT_PUBLIC_CAPI_HOST}/v1/apps/${process.env.NEXT_PUBLIC_APP_LOOKUP_KEY}/users/login_with_token`;

    const authToken = localStorage.getItem('CLEENG_AUTH_TOKEN')!
    if (authToken) {
      try {
        const { data } = await axios.post(prodURL, {
          cleengToken: authToken
        }, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
        localStorage.setItem('FANREACH_TOKEN', data.data.allianceCleengToken as string ?? '');
      } catch (error) {
        console.error(error);
      }
    } else {
      console.error('CLEENG_AUTH_TOKEN is not available');
    }
  }, []);

  const logoutLogin = () => {
    if (isLoggedIn === true) {
      localStorage?.clear();
      window?.location.reload();
      setIsLoggedIn(false);
      return true
    }
    else if (isLoggedIn === false) {
      return false
    }
  }

  const toggleUserMenu = () => {
    if (userMenuOpen) {
      setUserMenuOpen(false);
    } else {
      setUserMenuOpen(true);
    }
  }
  return <>
    <div>
      <div className={styles.navBar}>
        <div className={styles.burgerMenu}>
          {!userMenuOpen ?
            <MenuIcon style={{ color: "#FFFFFF", cursor: "pointer" }} onClick={() => toggleUserMenu()} /> :
            <CloseIcon style={{ color: "#FFFFFF", cursor: "pointer" }} onClick={() => toggleUserMenu()} />
          }
        </div>
        <div className={styles.homeIcon} style={{ marginTop: "4px" }}>
          <a target="_blank" href={urlWithToken} onClick={() => toggleUserMenu()}>
            <img src="/chl-logo.png" style={{ maxWidth: '100px', maxHeight: '100px' }} />
          </a>
        </div>
        <div className={styles.videoIcon}>
          <a target="_blank" href={urlWithToken} onClick={() => toggleUserMenu()}>
            <OndemandVideoIcon style={{ color: "#FFFFFF", cursor: "pointer" }} />
          </a>
        </div>
      </div>
      {userMenuOpen &&
        <ClickAwayListener onClickAway={() => setUserMenuOpen(false)}>
          <div className={styles.userMenu}>
            {isLoggedIn &&
              <>
                <h3 className={styles.userMenuH3}><Link href={`/offers`} onClick={() => toggleUserMenu()}>
                  <Trans i18nKey='menu.item1'>
                    Buy Subscriptions
                  </Trans>
                </Link>
                </h3>
                <h3 className={styles.userMenuH3}><Link href={`/userAccount`} onClick={() => toggleUserMenu()}>
                  <Trans i18nKey='menu.item2'>
                    Update Account
                  </Trans>
                </Link></h3>
                <h3 className={styles.userMenuH3}><Link href={`/updateProfile`} onClick={() => toggleUserMenu()} >
                  <Trans i18nKey='menu.item3'>
                    Change Password
                  </Trans>
                </Link></h3>
              </>}
            <h3 className={styles.userMenuH3}><a target="_blank" href={process.env.NEXT_PUBLIC_SUPPPORT_URL} onClick={() => toggleUserMenu()}>
              <Trans i18nKey='menu.item4'>
                Support
              </Trans>
            </a></h3>
            <h3 className={styles.userMenuH3}><a target="_blank" href={urlWithToken} onClick={() => toggleUserMenu()} >
              <Trans i18nKey='menu.item5'>
                Watch Site
              </Trans>
            </a></h3>
            {isLoggedIn &&
              <h3 className={styles.userMenuH3}><a href={`/`} onClick={() => logoutLogin()}>
                <Trans i18nKey='menu.item6'>
                  Sign Out
                </Trans>
              </a></h3>
            }
          </div>
        </ClickAwayListener>
      }
    </div>
  </>
};
